<script setup lang="ts">
import {
  Carousel, Slide, Pagination,
} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';
import Employee from './Employee.vue';

const testimonies = [
  {
    reviewer: 'EMMA, SCHOOL SUCCESS',
    quote: "The best part about working at GradGuard is the people. Being surrounded by teammates who challenge me and encourage both personal and professional growth is what makes this culture so unique. We’re committed to prioritizing transparency and honesty not only with each other but with the clients and customers that we're fortunate enough to work with each and every day.",
    image: '/images/careers/Emma.png',
  },
  {
    reviewer: 'DAVID, TECHNOLOGY',
    quote: 'Working at GradGuard means immersing yourself in a role where innovation meets independence, surrounded by a team that thrives on collaboration. It’s a professional journey fueled by advanced technologies and a commitment to personal growth, underpinned by a principled culture of respect.',
    image: '/images/careers/David.png',
  },
];

</script>

<template>
  <Carousel :autoplay="20000" wrap-around pause-autoplay-on-hover>
    <Slide
      v-for="testimony in testimonies"
      :key="testimony.reviewer"
    >
      <div class="carousel__item h-full">
        <Employee
          :reviewer="testimony.reviewer"
          :quote="testimony.quote"
          :image="testimony.image"
        >
          <template #pagination>
            <Pagination />
          </template>
        </Employee>
      </div>
    </Slide>
    <template #addons>
      <!-- <Pagination /> -->
      <!-- <Navigation /> -->
    </template>
  </Carousel>
</template>

<style>
.carousel__item {
  width: 100%;
}

.carousel {
  --vc-pgn-background-color: rgb(39 109 187);
  --vc-pgn-active-color: rgb(39 109 187);
  --vc-pgn-border-radius: 9999px;
  --vc-pgn-height: 12px;
  --vc-pgn-width: 12px;
}

.carousel__pagination {
  gap: 2px;
  align-items: center;
}
.carousel__pagination-button--active::after {
  width: 30px;
}
</style>
